import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ChangeEvent, useContext, useState } from 'react';
import { Backdrop, Box, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import { SubmissionType } from '../../types/enums';
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import authService from '../../components/api-authorization/AuthorizeService';
import axios from 'axios';
import { useSignal } from '@preact/signals-react';
import { publishSubmissionMessageAsync } from '../ClaimFileUpload/claimFile.api';

export interface DialogAddMailingAsNomineeProps {
	CaseId: number | null;
	CaseNumber: string | null;
	CaseName: string | null;
}

export default function DialogAddMailingAsNominee(dialogProperties: DialogAddMailingAsNomineeProps) {
	const [open, setOpen] = useState(false);
	const [file, setFile] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);
	const [formData, setFormData] = useState<FormData | null>(null);
	const [error, setError] = useState<TypeError | undefined>(undefined as TypeError | undefined);
	const { user } = useContext(UserContext) as UserContextType;

	const uploading = useSignal(false);
	const progress = useSignal(0);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
		// keep users from being able to close the dialog by clicking the backdrop
		if (reason == 'backdropClick') {
			return
		}

		uploading.value = false;
		setFormData(null);
		setFile(null);
		setSuccess(false);
		setError(undefined);
		setOpen(false);
	};

	function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
		setFormData(null);
		setFile(null);
		setSuccess(false);
		setError(undefined);

		const file = event.target.files?.[0];

		if (file) {
			const formData = new FormData();
			formData.append('claimFile', file);
			formData.append('caseId', dialogProperties.CaseId?.toString() ?? '');
			formData.append('userId', user.id.toString());
			formData.append('submissionType', SubmissionType.MailingFile.toString());
			formData.append('parentSubmissionId', "0");
			formData.append('institutionId', user.institutionId.toString());
			formData.append('statedClaimantCount', "0");
			formData.append('statedTransactionCount', "0");

			setFormData(formData);
			setFile(file.name);
		} else {
			setFile(null);
			setFormData(null);
		}
	}

	function waitForInterval() {
		// simulates a file upload
		return new Promise<void>((resolve) => {
			let interval = setInterval(() => {
				progress.value += 10;

				if (progress.value >= 100) {
					clearInterval(interval);
					resolve();
				}
			}, 500);
		});
	}

	const handleContinue = async () => {
		uploading.value = true;

		// keep this code around for simulating a file upload
		//(async function () {
		//    await waitForInterval();
		//    setSuccess(true);
		//    uploading.value = false;
		//})();

		let token = await authService.getAccessToken();

		axios.post('api/Intake/UploadClaimFileAsync', formData, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			onUploadProgress: (progressEvent) => {
				uploading.value = true;
				progress.value = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 0));
			}
		})
			.then(response => {
				console.log('File uploaded successfully:', response.data);
				publishSubmissionMessageAsync(response.data.submissionId);
				setSuccess(true);
				uploading.value = false;
			})
			.catch(error => {
				console.error('Error uploading file:', error);
				setError(error);
				uploading.value = false;
			});
	}

	function handleHelp() {
		alert('This dialog allows you to add a mailing list to the case.  Click "Choose File" to select a file from your computer.  Click "Continue" to upload the file to the case.');
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: 9999999 }}
				open={uploading.value}
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<CircularProgress color="inherit" />

					<Typography variant="h6" sx={{ mt: 2 }}>
						Uploading and preprocessing file...
					</Typography>
				</Box>
			</Backdrop>

			{/* Add Mailing Button */}
			<Button variant="text" onClick={handleClickOpen}>
				<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" style={{ marginRight: "8px" }}>
					<path d="M14.7221 8.80096H8.41262V14.7879H6.30946V8.80096H0V6.80531H6.30946V0.818359H8.41262V6.80531H14.7221V8.80096Z" fill="#2193B0" />
				</svg>

				Add Mailing
			</Button>

			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ marginRight: "8px" }}>Add Mailing List</span>
					<svg onClick={handleHelp} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: "pointer" }}>
						<path d="M12 11.5V16.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M12 7.51013L12.01 7.49902" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</DialogTitle>

				<DialogContent>
					<DialogContentText style={{ color: "inherit" }}>
						<b>Case:</b> {dialogProperties.CaseNumber}, {dialogProperties.CaseName}
					</DialogContentText>

					{!success &&
						<DialogContentText style={{ marginTop: "8px", marginBottom: "8px", fontWeight: "bold", color: "inherit" }}>New Mailing List</DialogContentText>
					}

					{success &&
						<>
							<DialogContentText style={{ marginTop: "8px", marginBottom: "8px", fontWeight: "bold", color: "inherit" }}>Thanks!  You have successfully added a mailing list!</DialogContentText>
							<DialogContentText style={{ marginTop: "8px", color: "inherit" }}><b>Mailing List:</b> {file}</DialogContentText>
						</>
					}

					{!success &&
						<Stack direction="column">
							<TextField
								error={error && error !== null}
								InputProps={{
									readOnly: true,
								}}
								fullWidth
								multiline
								variant="outlined"
								helperText={error && error.message}
								label={error && "Error"}
								value={file && file !== undefined
									? file
									: 'No file selected...'} >
							</TextField>

							<Button
								style={{
									color: '#fff',
									backgroundColor: '#007bff',
									width: "128px",
									marginTop: "8px"
								}}
								component="label"
								color="primary"
								variant="contained"
								disabled={uploading.value}
							>
								Select a file
								<input
									id="claimFileInput"
									name="claimFileInput"
									type="file"
									accept=".xlsx"
									hidden
									onChange={handleFileChange} />
							</Button>
						</Stack>
					}

					{uploading.value &&
						<Box sx={{ width: '100%', mt: 2 }}>
							<LinearProgress variant="determinate" value={progress.value} />
						</Box>
					}
				</DialogContent>

				<DialogActions>
					{!success &&
						<Button onClick={handleClose as React.MouseEventHandler<HTMLButtonElement>} color="primary" variant="outlined" disabled={uploading.value}>
							Cancel
						</Button>
					}

					{success && (
						<Button onClick={handleClose as React.MouseEventHandler<HTMLButtonElement>} color="primary" variant='contained' style={{
							color: '#fff',
							backgroundColor: '#007bff'
						}}>
							Close
						</Button>
					)}

					{!success &&
						<Button onClick={handleContinue} color="primary" disabled={!file || uploading.value} variant='outlined'>
							Continue
						</Button>
					}
				</DialogActions>
			</Dialog>
		</>
	);
}
