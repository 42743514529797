import React, { createContext, useEffect, useState } from 'react';
import authService from '../../components/api-authorization/AuthorizeService';

export interface IConfigurationContext {
    id: number;
    key: string;
    value: string;
}

export type ConfigurationContextType = {
    mailingSubmissionsFeatureEnabled: boolean
}

export const ConfigurationContext = createContext<ConfigurationContextType | null>(null);

const ConfigurationProvider = ({ children }: any) => {
    let [mailingSubmissionsFeatureEnabled, setMailingSubmissionsFeatureEnabled] = useState<boolean>(false);

    useEffect(() => {
        const populateState = async () => {
            try {
                const token = await authService.getAccessToken();

                // if we weren't able to retrieve the token, then just return
                if (!token) {
                    return;
                }

                let response = await fetch(`api/Configuration/GetConfigValueByKey/mailingSubmissionsFeatureEnabled`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                let configuration: IConfigurationContext = await response.json();
                let value = parseBooleanConfigurationValue(configuration.value);
                setMailingSubmissionsFeatureEnabled(value);
            }

            // eat the error
            catch (error) {
                console.log(error);
                throw error;
            }
        }

        populateState();
    }, []);

    const parseBooleanConfigurationValue = (value: string): boolean => {
        const lowerCaseValue = value.trim().toLowerCase();
        return lowerCaseValue === "true" || lowerCaseValue === "1" || lowerCaseValue === "yes";
    }

    return (
        <ConfigurationContext.Provider value={{ mailingSubmissionsFeatureEnabled }}>
            {children}
        </ConfigurationContext.Provider>
    );
}

export default ConfigurationProvider;